<template>
  <on-enter-navigate-card :data="data" :class="{'hero' : isHero}">
    <div
      ref="root"
      :data-testid="componentID(vertical)"
      class="large-recommended"
      :class="[{'hero-size' : isHero}, {'is-active' : isActive}]"
      tabindex="-1">
      <transition name="fade-card">
        <div v-show="showOverlay" class="hover-overlay overlay"/>
      </transition>
      <router-link
        :to="data.canonicalURL || '#'"
        tabindex="-1"
        @click="onClick">
        <div class="martech-card shadow">
          <img v-if="hasRoot" :src="imageURL(data.imageURL || fallbackImage, { maxDimensions: 'autoxauto', quality: 75, })" alt="" class="martech-card-background" @error="errorImageFallback($event)">
          <div class="martech-card-content">
            <div v-if="showVerticalLink" class="vertical">
              <router-link :to="getVerticalLink" class="martech-labels martech-white" tabindex="-1">
                {{ getArticleTypeTag }}
              </router-link>
            </div>
            <h2 class="martech-semi-condensed martech-text-bold martech-sub-heading martech-text-uppercase martech-two-lines">
              {{ data.title }}
            </h2>
            <div class="author-date">
              <author-picture-template
                :source="data.authorImageURL"
                size="medium"/>
              <div class="group">
                <p class="author martech-text-sm">
                  By
                  <router-link
                    :to="data.authorURL || '#'"
                    class="link martech-text-sm"
                    tabindex="0"
                    @keyup.enter.stop>
                    {{ data.authorName }}
                  </router-link>
                </p>
                <p v-if="showDate" class="date martech-text-sm">
                  {{ formattedDate }}
                </p>
              </div>
            </div>
          </div>
          <div class="martech-card-overlay"/>
        </div>
      </router-link>
    </div>
  </on-enter-navigate-card>
</template>

<script setup>
import { computed } from 'vue';
import AuthorPictureTemplate from '@/components/templates/AuthorPictureTemplate.vue';
import OnEnterNavigateCard from '@/components/card-elements/OnEnterNavigateCard.vue';
import {
  DateHelper as dates,
  ImageHelper as image,
  VerticalHelpers as verts,
  useImageAlter
} from '@tcgplayer/martech-components';
import amplitudeEvent from '@tcgplayer/amplitude';

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: () => ({
      title: '',
      imageURL: '',
      date: '',
      canonicalURL: '',
      contentType: '',
      authorURL: '',
      authorName: '',
      authorImageURL: '',
      vertical: '',
    }),
  },
  showVerticalLink: {
    type: Boolean,
    required: false,
    default: false,
  },
  showDate: {
    type: Boolean,
    required: false,
    default: true,
  },
  isHero: {
    type: Boolean,
    required: false,
    default: false,
  },
  isActive: {
    type: Boolean,
    required: false,
    default: false,
  },
  showOverlay: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { imageURL, hasRoot } = useImageAlter();

const vertical = computed(() => props.data.vertical);
const fallbackImage = computed(() => image.getFallbackImage(props.data.vertical, 'card'));
const formattedDate = computed(() => dates.format(props.data.date));
const getVerticalLink = computed(() => verts.verticalLink(vertical.value || ''));
const getArticleTypeTag = computed(() => verts.displayName(vertical.value || ''));

let fallbackTried = false; // Make sure we don't loop forever
const errorImageFallback = (event) => {
  if (fallbackTried) return;
  fallbackTried = true;
  event.target.src = fallbackImage;
};

const onClick = () => {
  const data = {
    title: props.data.title,
    productLine: props.data.vertical,
    format: props.data?.format || '',
    url: props.data.canonicalURL,
    source: 'Large Recommended',
    author: props.data.authorName,
  };

  if (props.data.date || props.data.dateTime) {
    try {
      data.datePublished = new Date(props.data.dateTime || props.data.date).toISOString();
    } catch (_) { /* Just in case */ }
  }

  amplitudeEvent('infinite', 'infiniteArticleClicked', data);
};
</script>

<style lang="scss" scoped>
.large-recommended {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  border-radius: $martech-radius-medium;
  background-color: $martech-black;

  @include breakpoint(768) {
    height: 280px;
  }

  .martech-card {
    width: 100%;
    height: 100%;

    &-background {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      background-color: $martech-black;
    }

    &-content {
      width: 100%;
      position: absolute;
      z-index: 20;
      bottom: 0;
      padding: $martech-spacer-3;
      text-shadow: 1px 1px 0 $martech-black, 0 0 16px $martech-black;

      .vertical {
        padding-bottom: $martech-spacer-1;
      }

      h2 {
        color: $martech-white;
        margin-bottom: $martech-spacer-3;
        letter-spacing: 1px;
      }

      .author-date {
        display: flex;
        align-items: center;

        p {
          padding-left: $martech-spacer-2;
        }

        :deep(.medium) {
          width: 52px;
          height: 52px;
        }
      }

      .author,
      .link {
        color: $martech-white;
        font-weight: $martech-weight-bold;

        &:hover {
          text-decoration: underline;
        }
      }

      .date {
        color: $martech-white;
      }
    }

    &-overlay {
      position: absolute;
      height: 80%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.65),
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 1)
      );
      z-index: 10;
    }
  }
}

.hover-overlay {
  position: absolute;
  z-index: 25;
  width: 100%;
  height: 100%;
  background: $martech-black;
  opacity: 0.5;
}

.fade-card-enter-active {
  transition: opacity 400ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.fade-card-leave-active {
  transition: opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.fade-card-enter,
.fade-card-leave-to {
  opacity: 0;
}

.hero {
  &:nth-child(1) {
    .hero-size {
        &:nth-of-type(1) {
          @include breakpoint(768) {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
      }
    }
  }
  &:nth-child(2) {
    .hero-size {
        &:nth-of-type(1) {
          @include breakpoint(768) {
            border-radius: 0px;
        }
      }
    }
  }
  &:nth-child(3) {
    .hero-size {
        &:nth-of-type(1) {
          @include breakpoint(768) {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
      }
    }
  }
}

.hero-size {
  height: 240px;
  margin-bottom: $martech-spacer-3;

  @include breakpoint(768) {
    height: 380px;
  }

  @include breakpoint(1024) {
    height: 480px;
  }

  .martech-card {
    @include breakpoint(768) {
      border-radius: 0px;
    }
  }

  .martech-card-background {
    transition: transform 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  &.is-active {
    .martech-card-background {
      transform: scale(1.16);
    }
  }
}
</style>
