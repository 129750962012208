import Api from '@/api/api';

function getVerticals() {
  return Api.getVerticals().then(response => response?.data?.result || []);
}

function getMoreGamesVerticals() {
  return getVerticals().then(response => response.filter(v => ![ 'Magic', 'YuGiOh', 'Pokemon', 'Lorcana', 'Flesh and Blood', 'Announcements' ].includes(v.verticalName)));
}

export default {
  getVerticals,
  getMoreGamesVerticals,
  moreGames: await getMoreGamesVerticals().then(verticals => verticals.map(v => v.displayName)),
  moreGamesLinks: [ { text: 'All Articles', url: '/more-games' } ].concat(
    await getMoreGamesVerticals().then(verticals => verticals.map(v => (
      {
        text: v.displayName, url: `/more-games/${v.urlName}`,
      }
    )))
  ).filter(t => ![ 'Announcements', 'General', 'Edge' ].includes(t.text)),
  filterOptions: Object.assign(
    { 'all games': 'All Games' },
    await getVerticals().then(response => response.filter(v => v.verticalName !== 'Announcements').reduce((a, v) => ({ ...a, [v.verticalName.toLowerCase()]: v.displayName }), {}))
  ),
};
