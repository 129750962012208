<template>
  <delay-load @visible="onVisible">
    <div class="latest-video" :data-testid="componentID()">
      <div v-if="!loading && (video.label || (deviceType === 'desktop' && video.articleCanonicalURL))" class="header">
        <h2 class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
          {{ video.label }}
        </h2>
        <router-link v-if="deviceType === 'desktop' && video.articleCanonicalURL" :to="video.articleCanonicalURL || '#'" class="martech-see-all-link">
          Read Article
        </router-link>
      </div>
      <!-- placeholder component -->
      <div v-if="loading" class="placeholder">
        <full-art-placeholder :video="true" :include-text="false" class="item"/>
      </div>
      <div v-if="!loading" class="video">
        <twitch-embed v-if="video && platform === 'Twitch'" :id="video.videoID" :channel="video.videoType === 'Channel'"/>
        <youtube-embed v-if="video && platform === 'YouTube'" :id="video.videoID" :start="video.start"/>
      </div>
      <div v-if="!loading && deviceType === 'mobile' && video.articleCanonicalURL" class="footer">
        <router-link :to="video.articleCanonicalURL || '#'" class="martech-see-all-link">
          Read Article
        </router-link>
      </div>
    </div>
  </delay-load>
</template>

<script>
import delve from 'dlv';
import Api from '@/api/api';
import deviceType from '@/mixins/deviceType';
import DelayLoad from '@/components/DelayLoad.vue';
import TwitchEmbed from '@/components/embed/TwitchEmbed.vue';
import YoutubeEmbed from '@/components/embed/YoutubeEmbed.vue';
import FullArtPlaceholder from '@/components/placeholder-components/FullArtPlaceholder.vue';

export default {
  name: 'video-content',
  components: {
    DelayLoad,
    TwitchEmbed,
    YoutubeEmbed,
    FullArtPlaceholder,
  },
  mixins: [ deviceType ],
  props: {
    verticalName: {
      type: String,
      required: false,
      default: '',
    },
    platform: {
      type: String,
      required: false,
      default: '', // (YouTube, Twitch)
    },
    editorsChoice: {
      type: Boolean,
      required: false,
      default: true,
    },
    offset: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      video: null,
      loading: true,
    };
  },
  methods: {
    async onVisible() {
      const vertOrTag = this.verticalName === 'homepage' ? 'tag' : 'game';
      const params = {
        platform: this.platform,
        editorsChoice: true,
        offset: this.offset,
        rows: 1,
      };
      params[vertOrTag] = this.verticalName;
      const res = await Api.getVideos(params);
      this.video = delve(res, 'data.result.0', {});

      if (this.video.autoLiveStream === '1') {
        // Try to find a live stream and switch out the videoID & type if we find one
        const live = delve(await Api.getLiveStreams(), 'data.result.0');
        if (live) {
          [ this.video.videoType, this.video.videoID ] = [ 'Channel', live.channel ];
          this.video.label = `Featured Live Stream: ${live.name}`;
        }
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 35px;
  padding-bottom: $martech-spacer-2;
}

.placeholder {
  height: 100%;

  @include breakpoint(1024) {
    height: 390px;
  }

  .item {
    height: 100%;

    @include breakpoint(1024) {
      height: 390px;
    }
  }
}

.video {
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  position: relative;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 48px;
}
</style>
