<template>
  <div>
    <metainfo/>
    <grid-wrapper>
      <template v-slot:header>
        <header>
          <!--eslint-disable-next-line vue/no-v-model-argument-->
          <navigation-header v-model:height="totalHeaderHeight"/>
        </header>
      </template>
      <template v-slot:main>
        <main :style="{top: `${totalHeaderHeight}px`}" :class="[{'no-top-pad' : ['Home', 'Commander Bestiary'].includes($route.name)}, {'subscriptions' : subscriptionsRoute}]">
          <router-view :key="$route.fullPath"/>
        </main>
      </template>
      <template v-slot:footer>
        <footer v-if="$route.meta.showMainNavigation" :style="{top: `${totalHeaderHeight}px`}">
          <subscriptions-promo-banner v-if="!$route.meta.hideSubsPromo"/>
          <page-footer/>
          <return-link/>
          <exit-intent-newsletter-popup v-if="$route.meta.showMainNavigation && !complianceShown"/>
          <compliance-banner @banner-state="complianceUpdate"/>
        </footer>
      </template>
    </grid-wrapper>
  </div>
</template>

<script>
import PageFooter from '@/components/footer-elements/PageFooter.vue';
import GridWrapper from '@/components/grid/GridWrapper.vue';
import NavigationHeader from '@/components/navigation-elements/NavigationHeader.vue';
import ExitIntentNewsletterPopup from '@/components/newsletters/ExitIntentNewsletterPopup.vue';
import ReturnLink from '@/components/return-link/ReturnLink.vue';
import SubscriptionsPromoBanner from '@/components/subscriptions/SubscriptionsPromoBanner.vue';
import { setUserProperties } from '@tcgplayer/amplitude';
import { ComplianceBanner, BlockerHelper } from '@tcgplayer/martech-components';
import { useHead } from '@vueuse/head';
import delve from 'dlv';
import {
  computed, ref, watch, getCurrentInstance
} from 'vue';
import { useRoute } from 'vue-router';
import useVerticals from '@/use/Verticals';

export default {
  name: 'app',
  components: {
    ComplianceBanner,
    NavigationHeader,
    GridWrapper,
    PageFooter,
    ReturnLink,
    ExitIntentNewsletterPopup,
    SubscriptionsPromoBanner,
  },
  setup() {
    const href = ref(`${window.location.protocol}//${window.location.host}${window.location.pathname}`);
    const route = useRoute();
    const siteConfig = delve(getCurrentInstance(), 'appContext.config.globalProperties.siteConfig', () => (''));
    const { verticalType } = useVerticals();

    watch(
      () => route.fullPath,
      () => {
        href.value = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      }
    );

    const openGraph = siteConfig('global.opengraph.image');
    const description = siteConfig('global.opengraph.description');

    // Default title, links, meta tags in the head unless overridden by other pages/components
    useHead({
      title: computed(() => 'TCGplayer Infinite'),
      link: computed(() => ([
        { name: 'canonical_url', rel: 'canonical', href: href.value },
      ])),
      meta: [
        { name: 'description', content: description },
        { name: 'robots', content: 'index, follow' },
        { property: 'title', content: 'TCGplayer Infinite' },
        { property: 'og:description', content: description },
        { property: 'og:image', content: openGraph },
        { property: 'og:title', content: computed(() => route.name || 'TCGplayer Infinite') },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: computed(() => `${process.env.VUE_APP_BASE_URL}${route.fullPath || '/'}`) },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:creator', content: '@TCGplayer' },
        { name: 'twitter:description', content: description },
        { name: 'twitter:image', content: openGraph },
        { name: 'twitter:image:alt', content: description },
        { name: 'twitter:site', content: '@TCGplayer' },
        { name: 'twitter:text:title', content: 'TCGplayer Infinite' },
        { name: 'twitter:title', content: computed(() => route.name || 'TCGplayer Infinite') },
      ],
    });

    const totalHeaderHeight = ref();

    return {
      href,
      verticalType,
      totalHeaderHeight,
    };
  },
  data() {
    return {
      complianceShown: false,
    };
  },
  computed: {
    subscriptionsRoute() {
      return this.$route.path.includes('/premium');
    },
  },
  mounted() {
    // Log session UTM properties
    window.setTimeout(() => {
      const utmProperties = {};
      if (delve(this, '$route.query.utm_campaign')) utmProperties.session_utm_campaign = this.$route.query.utm_campaign;
      if (delve(this, '$route.query.utm_content')) utmProperties.session_utm_content = this.$route.query.utm_content;
      if (delve(this, '$route.query.utm_medium')) utmProperties.session_utm_medium = this.$route.query.utm_medium;
      if (delve(this, '$route.query.utm_source')) utmProperties.session_utm_source = this.$route.query.utm_source;
      if (delve(this, '$route.query.utm_term')) utmProperties.session_utm_term = this.$route.query.utm_term;
      if (Object.keys(utmProperties).length) {
        setUserProperties(utmProperties);
      }
    }, 0);

    this.initAccessibility();

    if (process.env.VUE_APP_BLOCKER_DETECTION === 'true') {
      BlockerHelper.detect();
    }
  },
  methods: {
    complianceUpdate(state) {
      this.complianceShown = state;
    },
    initAccessibility() {
      // If user is uing a mouse remove focus-visible
      document.body.addEventListener('mouseover', () => {
        document.body.classList.add('using-mouse');
      }, { passive: true });

      // Remove accessibility border if the user is focused on the input
      document.body.addEventListener('keydown', (e) => {
        const hideBorder = e.target.tagName !== 'INPUT';

        if (hideBorder) {
          document.body.classList.remove('using-mouse');
        }
      }, { passive: true });

      // Remove event listeners if body contains using-mouse class
      if (document.body.classList.contains('using-mouse')) {
        document.body.removeEventListener('mouseover');
        document.body.removeEventListener('keydown');
      }
    },
  },
};
</script>

<style scoped lang="css">
  @import "@tcgplayer/martech-components/dist/style.css";
</style>

<style scoped lang="scss">
main {
  background: $martech-white;
  height: 100%;
  min-height: calc(100vh - 358px);
  display: inline-block;
  position: relative;
  padding: $martech-spacer-4 0;

  &.subscriptions {
    padding: 0;
  }

  &.no-top-pad {
    padding: 0 0 $martech-spacer-4 0;
  }
}

footer {
  height: 130px;
  position: relative;
  top: 58px;
}
</style>
