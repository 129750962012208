<template>
  <div v-if="heroData" :data-testid="componentID()">
    <div class="homepage-hero">
      <base-link
        v-if="heroData.buttonURL"
        :link-url="heroData.buttonURL"
        :url-target="target"
        :new-window="!!target"
        @click="analyticsEvent">
        <div class="container">
          <div class="homepage-hero__wrapper" :class="[alignmentStyles, colorStyles]">
            <div class="hero-content">
              <p v-if="heroData.vertical" class="vertical martech-labels">
                {{ verticalDisplayName }}
              </p>
              <p v-if="heroData.subtitle" class="martech-body">
                {{ heroData.subtitle }}
              </p>
              <h1 class="martech-title hero-title">
                {{ heroData.title }}
              </h1>
              <p v-if="heroData.text" class="martech-text-md">
                {{ heroData.text }}
              </p>
              <div class="button-group">
                <base-link
                  v-if="heroData.buttonURL"
                  class="hero-button"
                  :link-url="heroData.buttonURL"
                  url-target="_blank">
                  {{ heroData.buttonText }}
                </base-link>
                <base-link
                  v-if="heroData.termsURL"
                  class="hero-link"
                  :link-url="heroData.termsURL"
                  url-target="_blank">
                  {{ heroData.termsText }}
                </base-link>
              </div>
            </div>
          </div>
        </div>
      </base-link>
      <div v-if="!heroData.buttonURL" class="container">
        <div class="homepage-hero__wrapper" :class="[alignmentStyles, colorStyles]">
          <div class="hero-content">
            <p v-if="heroData.vertical" class="vertical">
              {{ verticalDisplayName }}
            </p>
            <p v-if="heroData.subtitle" class="hero-subtitle">
              {{ heroData.subtitle }}
            </p>
            <h1 class="hero-title">
              {{ heroData.title }}
            </h1>
            <p v-if="heroData.text" class="hero-text">
              {{ heroData.text }}
            </p>
          </div>
        </div>
      </div>
      <img v-show="heroImageURL" ref="imageRoot" :src="imageURL(heroImageURL, { maxDimensions: 'autoxauto', quality: 75, })" :alt="heroData.title" class="hero-image">
      <div class="radial-gradient"/>
    </div>
  </div>
  <div v-else class="homepage-hero-no-data"/>
</template>

<script>
import delve from 'dlv';
import amplitudeEvent from '@tcgplayer/amplitude';
import { BaseLink, VerticalHelpers as verts } from '@tcgplayer/martech-components';
import deviceType from '@/mixins/deviceType';
import imageAlter from '@/mixins/image/alter';

export default {
  name: 'homepage-hero',
  components: {
    BaseLink,
  },
  mixins: [ deviceType, imageAlter ],
  props: {
    heroData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  computed: {
    alignmentStyles() {
      switch (this.heroData.textAlignment) {
        case 'left':
        case 'right':
          return this.heroData.textAlignment;
        default:
          return 'center';
      }
    },
    colorStyles() {
      switch (this.heroData.textColor) {
        case 'black':
          return this.heroData.textColor;
        default:
          return 'white';
      }
    },
    target() {
      if (this.heroData.newTab) {
        return '_blank';
      }
      return '';
    },
    heroImageURL() {
      return this.deviceType === 'mobile' ? delve(this, 'heroData.mobileImageURL', '') : delve(this, 'heroData.imageURL', '');
    },
    verticalDisplayName() {
      return verts.displayName(this.heroData.vertical);
    },
  },
  methods: {
    analyticsEvent() {
      const match = this.heroData.buttonURL.match(/article\/(.+)/);
      const articleTitle = (match && match.length > 1) ? match[1] : '';
      amplitudeEvent('infinite', 'infiniteHeroClicked', {
        articleTitle,
        path: this.heroData.buttonURL,
        title: this.heroData.title,
        productLine: this.heroData.vertical,
        productAffinity: this.heroData.productLineAffinity,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.homepage-hero-no-data {
  width: 100%;
  min-height: 414px;

  @include breakpoint(1024) {
    min-height: 358px;
  }
}
.homepage-hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  position: relative;
  background-color: $martech-black;
  background-position: center center;
  height: 320px;

  :deep(span) {
    display: inherit;

    a {
      width: 100%;
      margin: 8px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    padding: $martech-spacer-5 0;

    .martech-body {
      margin: 0;
    }

    .hero-title {
      padding: $martech-spacer-2 0;
      margin: 0;
      max-width: 22ch;
      font-family: $martech-display-semi-condensed;

      @include breakpoint(1024) {
        max-width: 35ch;
      }
    }

    :deep(.hero-button) {
      margin-top: $martech-spacer-3;
      align-items: center;
      background-color: $martech-white;
      color: $martech-black;
      font-size: $martech-type-14;
      padding: 0 $martech-spacer-2;
      min-height: 40px;
      font-weight: $martech-weight-semibold;
      text-decoration: none;
      border: 1px solid #dee3e7;
      border-radius: $martech-radius-medium;
      text-shadow: none;

      a {
        color: $martech-black !important;
      }
    }

    .vertical {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.25));
      border-radius: 16px;
      display: inline-block;
      padding: $martech-spacer-1 $martech-spacer-3;
      margin-bottom: $martech-spacer-2;
    }

    .button-group {
      display: inline-flex;
      flex-direction: column;
    }

    .hero-link {
      :deep(a) {
        font-family: $martech-display-inter;
        font-size: $martech-type-14; // 14px
        line-height: 1.25rem; // 20px
        letter-spacing: 0.4px;
        font-weight: $martech-weight-normal;
        padding-top: $martech-spacer-3;
        transition: underline 100ms ease-in-out;
      }
    }

    &.white {
      .hero-content {
        text-shadow: 1px 1px $martech-black, 0px 0px 16px $martech-black;
        color: $martech-white;

        .vertical {
          color: $martech-white;
        }

        h1,
        p,
        :deep(a) {
          color: $martech-white;
        }

        .hero-link {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &.black {
      .hero-content {
        h1,
        p,
        :deep(a) {
          color: $martech-black;
        }

        .vertical {
          color: $martech-white;
        }

        .hero-link {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &.center {
      align-items: center;

      .hero-content {
        text-align: center;
      }
    }

    &.left {
      align-items: flex-start;

      .hero-content {
        text-align: left;

        @include breakpoint(1024) {
          width: 50%;
        }
      }
    }

    &.right {
      align-items: flex-end;

      .hero-content {
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include breakpoint(1024) {
          width: 50%;
        }
      }
    }
  }

  .hero-image {
    position: absolute;
    object-fit: cover;
    object-position: top center;
    width: 100%;
    height: 320px;
    top: 0;
    bottom: 0;
    z-index: 0;
  }

  .radial-gradient {
    background: radial-gradient(transparent, rgba(0, 0, 0, 0.2));
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}
</style>
