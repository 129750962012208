<template>
  <delay-load @visible="onVisible">
    <div class="series">
      <div class="container">
        <div class="series-header">
          <h2 class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
            TCGplayer Series
          </h2>
          <router-link
            to="/series"
            aria-label="See All TCGplayer Series"
            class="martech-see-all-link">
            See All
          </router-link>
        </div>
      </div>
      <div class="series-body">
        <div class="container" :class="{'mobile-scroll' : isMobile}">
          <div class="series-body__lg-buttons" :class="{'mobile-scroll' : isMobile}">
            <template v-if="loading">
              <div
                v-for="p in largeSpotlights.length"
                :key="p"
                class="slides">
                <full-art-placeholder placeholder-height="182px"/>
              </div>
            </template>
            <template v-else>
              <div
                v-for="spotlight in filteredLargeSpotlights"
                :key="spotlight"
                class="slides">
                <spotlight-button-card
                  :data="spotlight"
                  :link-url="`/series/${spotlight?.name?.replaceAll(' ', '-')}`"/>
              </div>
            </template>
          </div>
        </div>
        <div class="container">
          <div class="series-body__sm-buttons">
            <template v-if="loading">
              <small-card-placeholder v-for="p in smallSpotlights.length" :key="p" class="item"/>
            </template>
            <template v-else>
              <series-card
                v-for="s in filteredSmallSpotlights"
                :key="s"
                :data="s"
                :link-url="`/series/${s?.name?.replaceAll(' ', '-')}`"/>
            </template>
          </div>
        </div>
      </div>
    </div>
  </delay-load>
</template>

<script setup>
import { ref, computed } from 'vue';
import { get, set } from '@vueuse/core';
import { SpotlightButtonCard, SeriesCard, useDeviceType } from '@tcgplayer/martech-components';
import FullArtPlaceholder from '@/components/placeholder-components/FullArtPlaceholder.vue';
import SmallCardPlaceholder from '@/components/placeholder-components/SmallCardPlaceholder.vue';

import Api from '@/api/api';
import DelayLoad from '@/components/DelayLoad.vue';

const { isMobile } = useDeviceType();

const series = ref([]);
const loading = ref(true);
const largeSpotlights = [ 'commander upgrade', 'bestselling cards of the week', 'the cards everybody wants' ];
const smallSpotlights = [ 'movers and shakers', 'how to build', 'financial set review', 'metagame recap' ];

async function onVisible() {
  const params = {
    domains: 'infinite',
  };

  const res = await Api.getSeries(params);

  set(series, res?.data?.result || []);
  set(loading, false);
}

const filteredLargeSpotlights = computed(() => get(series).filter(t => (get(largeSpotlights) || []).includes(t.name.toLowerCase())));

const filteredSmallSpotlights = computed(() => get(series).filter(t => (get(smallSpotlights) || []).includes(t.name.toLowerCase())));
</script>

<style lang="scss" scoped>
.series {
  width: 100%;

  .container {
    &.mobile-scroll {
      padding: 0;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $martech-spacer-3;
  }

  &-body {
    .mobile-scroll {
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
      overflow-x: scroll;
      width: 100vw;
      margin: -15px 0;
      padding: 32px 0;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .slides {
        &:first-of-type {
          padding-left: $martech-spacer-3;
        }

        &:last-of-type {
          padding-right: $martech-spacer-3;
        }
      }

      :deep(.spotlight-button),
      :deep(.placeholder) {
        width: 325px;
        scroll-snap-align: center;
      }
    }

    &__lg-buttons {
      width: 100%;
      display: flex;
      grid-gap: $martech-spacer-3;

      :deep(span) {
        width: 33.3%;
      }
    }

    .slides,
    :deep(.placeholder) {
      width: 100%;
    }

    &__sm-buttons {
      width: 100%;
      display: flex;
      grid-gap: $martech-spacer-3;
      flex-direction: column;
      margin-top: $martech-spacer-3;

      @include breakpoint(1024) {
        flex-direction: row;
      }

      :deep(.placeholder) {
        padding-left: 0;
      }

      :deep(span) {
        width: 100%;

        @include breakpoint(1024) {
          width: 25%;
        }
      }
    }
  }
}
</style>