<template>
  <div class="mobile-navigation" :data-testid="componentID()">
    <div id="main-menu" class="mobile-nav" :class="navDrawerVisibility">
      <div class="mobile-nav__header">
        <navigation-logo/>
        <span class="close-button">
          <close-toggle-button @close="toggleNavDrawer(false)"/>
        </span>
      </div>
      <div class="mobile-nav__links">
        <div class="dropdown">
          <div v-for="(menu, index) in dropdownItems" :key="'menu'+index" class="dropdown-item">
            <div
              class="dropdown-toggle"
              :class="{'is-active' : menu.open}"
              tabindex="0"
              aria-haspopup="true"
              :aria-expanded="menu.open"
              data-toggle="collapse"
              data-target="#dropdown-menu"
              @click="mainNavClick(index)">
              <template v-if="menu.isDropdownMenu">
                {{ menu.title }}
                <iconography-chevron class="chevron-down" direction="down"/>
              </template>
              <template v-else>
                <base-link :link-url="menu.home || '#'" class="home-link" tabindex="-1">
                  {{ menu.title }}
                </base-link>
              </template>
            </div>
            <template v-if="menu.isDropdownMenu">
              <transition name="fade">
                <div v-show="menu.open" id="dropdown-menu" class="dropdown-menu">
                  <ul v-if="menu.childRoutes">
                    <li v-for="(child, i) in menu.childRoutes.filter(t => !['General', 'Infinite', 'Announcements'].includes(t.text))" :key="i" tabstop="0">
                      <base-link :link-url="child.url || '#'" @click="linkClick(child.url, 'More Games', child.text)">
                        {{ child.text }}
                      </base-link>
                    </li>
                  </ul>
                  <ul v-else>
                    <li v-show="menu.game">
                      <base-link :link-url="menu.home || '#'" class="nav-link" @click="linkClick(menu.home, `${menu.title} Home`, menu.game)">
                        {{ menu.title }} Home
                      </base-link>
                    </li>
                    <li v-show="menu.game">
                      <base-link :link-url="menu.articles || '#'" class="nav-link" @click="linkClick(menu.articles, 'Articles', menu.game)">
                        Articles
                      </base-link>
                    </li>
                    <li v-show="menu.game && menu.bestiary">
                      <base-link :link-url="menu.bestiary || '#'" class="nav-link" @click="linkClick(menu.bestiary, 'Commander Bestiary', menu.game)">
                        Commander Bestiary <span class="new martech-text-uppercase martech-text-semibold">&nbsp;New!</span>
                      </base-link>
                    </li>
                    <li v-show="siteConfig('navigation.premier').includes(menu.game)">
                      <base-link :link-url="menu.premier || '#'" class="nav-link" @click="linkClick(menu.premier, 'Premier Articles', menu.game)">
                        Premier Articles
                      </base-link>
                    </li>
                    <li v-show="['magic', 'yugioh', 'lorcana'].includes(menu.game)">
                      <base-link :link-url="menu.decks || '#'" class="nav-link" @click="linkClick(menu.decks, 'Decks & Events', menu.game)">
                        Decks & Events
                      </base-link>
                    </li>
                    <li v-show="['magic', 'yugioh'].includes(menu.game)">
                      <base-link :link-url="menu.search || '#'" class="nav-link" @click="linkClick(menu.search, `${menu.title} Deck Search`, menu.game)">
                        {{ menu.title }} Deck Search
                      </base-link>
                    </li>
                    <div v-show="menu.game" class="divider">
                      <hr>
                    </div>
                    <li v-show="menu.game" class="shop">
                      <base-link :link-url="menu.priceGuide" new-window url-target="_blank" class="nav-link" @click="linkClick(menu.priceGuide, `Shop ${menu.title}`, menu.game)">
                        {{ menu.title }} Price Guide
                      </base-link>
                      <div class="martech-icon martech-external-link-icon martech-black"/>
                    </li>
                    <li v-show="menu.game" class="shop">
                      <base-link :link-url="menu.shop" new-window url-target="_blank" class="nav-link" @click="linkClick(menu.shop, `Shop ${menu.title}`, menu.game)">
                        Shop {{ menu.title }}
                      </base-link>
                      <div class="martech-icon martech-external-link-icon martech-black"/>
                    </li>
                    <li v-for="item in menu.links" :key="item" tabstop="0">
                      <base-link
                        :link-url="item.link"
                        :url-target="item.externalLink ? '_blank' : ''"
                        @click="linkClick(item.link, item.linkText, menu.game)">
                        {{ item.linkText }}
                        <div v-if="item.externalLink" class="martech-icon martech-external-link-icon martech-black"/>
                      </base-link>
                    </li>
                  </ul>
                </div>
              </transition>
            </template>
          </div>
        </div>
        <div class="secondary-menu">
          <ul>
            <li v-for="(item, index) in secondaryMenu" :key="index">
              <base-link :link-url="item.link" new-window url-target="_blank" class="nav-link" @click="linkClick(item.link, item.title)">
                {{ item.title }}
              </base-link>
              <div v-show="item.externalLink" class="martech-icon martech-external-link-icon martech-black"/>
            </li>
          </ul>
        </div>
        <div class="secondary-menu">
          <ul>
            <li v-for="(item, index) in helpMenu" :key="index">
              <base-link :link-url="item.link" new-window url-target="_blank" class="nav-link" @click="linkClick(item.link, item.title)">
                {{ item.title }}
              </base-link>
              <div v-show="item.externalLink" class="martech-icon martech-external-link-icon martech-black"/>
            </li>
          </ul>
        </div>
        <mobile-newsletter-widget/>
      </div>
    </div>
    <!--screen overlay-->
    <transition name="fade">
      <screen-overlay :class="navDrawerVisibility" @close-overlay="closeDrawer"/>
    </transition>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { get } from '@vueuse/core';

import useNavigation from '@/use/Navigation';

import CloseToggleButton from '@/components/elements/CloseToggleButton.vue';
import ScreenOverlay from '@/components/elements/screenOverlay.vue';
import NavigationLogo from '@/components/navigation-elements/NavigationLogo.vue';
import MobileNewsletterWidget from '@/components/newsletters/MobileNewsletterWidget.vue';
import { BaseLink, IconographyChevron } from '@tcgplayer/martech-components';
import amplitudeEvent from '@tcgplayer/amplitude';

defineProps({
  subscriptionStatus: {
    type: String,
    required: false,
    default: '',
  },
  userName: {
    type: String,
    required: false,
    default: '',
  },
});

const route = useRoute();
const store = useStore();
const { dropdownItems } = useNavigation();

const secondaryMenu = [
  {
    title: 'TCGplayer Subscription',
    link: `${process.env.VUE_APP_BASE_URL}/premium/subscription`,
    externalLink: false,
  },
  {
    title: 'Shop TCGplayer',
    link: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}`,
    externalLink: true,
  },
];

const helpMenu = [
  {
    title: 'Help Center',
    link: `${process.env.VUE_APP_TCGPLAYER_HELP_URL}`,
    externalLink: true,
  },
  {
    title: 'Refund & Return Policy',
    link: `${process.env.VUE_APP_TCGPLAYER_HELP_URL}/hc/en-us/articles/201642073-Refund-and-Return-Policy`,
    externalLink: true,
  },
  {
    title: 'TCGplayer Safeguard',
    link: `${process.env.VUE_APP_TCGPLAYER_HELP_URL}/hc/en-us/articles/201637633-TCGplayer-Safeguard`,
    externalLink: true,
  },
  {
    title: 'TCGplayer Subscription FAQ',
    link: `${process.env.VUE_APP_TCGPLAYER_HELP_URL}/hc/en-us/articles/360035393833`,
    externalLink: true,
  }
];

const isDrawerVisible = computed(() => store.state.sidebar.isVisible);

const navDrawerVisibility = computed(() => ({
  'is-active': get(isDrawerVisible),
}));

const toggleNavDrawer = (val) => {
  store.dispatch('sidebar/toggleNavDrawer', val);
};

const closeDrawer = () => {
  toggleNavDrawer(false);
};

const linkClick = (linkURL, linkTitle, linkParent) => {
  amplitudeEvent('infinite', 'infiniteNavigationClicked', {
    linkURL,
    linkTitle,
    linkParent,
    location: 'primary',
  });
};

const mainNavClick = (i) => {
  get(dropdownItems)[i].open = !get(dropdownItems)[i].open;

  if (get(dropdownItems)[i].open) {
    linkClick(get(dropdownItems)[i].home, get(dropdownItems)[i].title);
  }
};

watch(
  () => get(route).fullPath,
  () => {
    closeDrawer();
  }
);
</script>

<style lang="scss" scoped>
.mobile-navigation {
  .mobile-nav {
    display: inherit !important;
    position: fixed;
    background: $martech-white;
    top: 0;
    width: 0;
    bottom: 0;
    overflow-y: scroll;
    z-index: 999;
    height: 100vh;
    transition: all 300ms ease-in-out;
    right: -400px;

    &.is-active {
      right: 0;
      width: 290px;

      @include breakpoint(420) {
        width: 315px;
      }
    }

    &__header {
      height: 60px;
      border-bottom: 1px solid $martech-border;
      padding: 0 $martech-spacer-3;
      display: flex;
      align-items: center;
      width: 100%;

      .close-button {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }

    &__links {
      padding: $martech-spacer-2 0;

      .dropdown {
        border-bottom: 1px solid $martech-border;

        &-toggle {
          height: 48px;
          padding: 0 $martech-spacer-3;
          display: flex;
          align-items: center;
          letter-spacing: 0.2px;
          font-weight: $martech-weight-semibold;
          transition: 0.15s ease-in;
          border-left: 4px solid $martech-white;
          transform: translate3d(0, 0, 0);
          justify-content: space-between;
          cursor: pointer;

          .chevron-down {
            margin-left: $martech-spacer-3;
            width: 11px;
            height: 6px;
          }

          .home-link {
            :deep(a) {
              color: $martech-text-primary;
            }
          }

          &.is-active {
            border-left: 4px solid $martech-blue;
            color: $martech-blue;

            .chevron-down {
              transition: transform 0.25s ease-in;
              transform: rotateX(180deg);
            }
          }
        }

        &-menu {
          ul {
            border-bottom: 1px solid $martech-border;

            li {
              padding-left: $martech-spacer-3;

              .nav-link, :deep(.martech-base-link) {
                color: $martech-gray-160;
                font-size: $martech-type-14;
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;

                .martech-icon {
                  margin-right: $martech-spacer-3;
                }
              }
            }
          }
        }
      }

      .divider {
        padding: 0 $martech-spacer-3;

        hr {
          margin: $martech-spacer-2 0;
        }
      }

      ul {
        list-style: none;
        border-left: 4px solid $martech-white;

        li {
          padding: 0 $martech-spacer-3;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:hover {
            background-color: $martech-blue-5;
          }
        }
      }
    }

    .secondary-menu {
      border-bottom: 1px solid $martech-border;

      :deep(.martech-base-link) {
        width: 95%;
        color: $martech-text-primary;
      }

      &:last-of-type {
        border-bottom: 0px;
      }
    }
  }

  .screen-overlay {
    display: none;

    &.is-active {
      display: block;
      z-index: 35;
    }
  }
}
</style>
