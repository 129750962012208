<template>
  <div>
    <div class="header">
      <h2 class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
        Featured Content
      </h2>
    </div>
    <div class="featured" :data-testid="componentID()">
      <template v-if="!orderedArticles.length">
        <full-art-placeholder
          v-for="p in rows"
          :key="p"
          placeholder-height="280px"
          class="item"/>
      </template>
      <template v-else>
        <recommended
          v-for="article in orderedArticles"
          :key="article.canonicalURL"
          :show-vertical-link="showVerticalLink"
          :show-date="showDate"
          :data="article"/>
      </template>
    </div>
  </div>
</template>

<script setup>
import { toValue, computed } from 'vue';
import FullArtPlaceholder from '@/components/placeholder-components/FullArtPlaceholder.vue';
import Recommended from '@/components/card-elements/Cards/Image/LargeRecommended.vue';
import { useFetchArticles } from '@/use/fetcher/useFetchArticles';
import { useUser } from '@/use/useUser';
import { useAffinityOrderData } from '@tcgplayer/martech-components';

const props = defineProps({
  showDate: {
    type: Boolean,
    default: true,
  },
  requiredVerticals: {
    type: Object,
    default: () => ({
      magic: 1, yugioh: 1, pokemon: 1, lorcana: 1, 'flesh and blood': 1,
    }),
  },
  featured: {
    type: Boolean,
    default: null,
  },
  rows: {
    type: Number,
    default: 6,
  },
  offset: {
    type: Number,
    default: 0,
  },
  placement: {
    type: String,
    default: 'Homepage',
  },
  uniqueResultsField: {
    type: String,
    default: 'uuid',
  },
  showVerticalLink: {
    type: Boolean,
    default: false,
  },
  skipArticlePool: {
    type: Boolean,
    default: false,
  },
});

const { productLineAffinity } = useUser();

const { articles } = useFetchArticles({
  count: props.rows,
  verticals: props.verticalName,
  requiredVerticals: toValue(props.requiredVerticals),
  featured: props.featured,
}, {
  skipPool: props.skipArticlePool,
});

const orderedArticles = computed(() => {
  const { orderedData } = useAffinityOrderData({
    data: articles.value,
    affinity: productLineAffinity || undefined,
    positions: [ 0, 2 ],
  });

  return orderedData.value;
});
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: $martech-spacer-2;
  min-height: 35px;
}
.featured {
  display: grid;
  grid-template-columns: repeat(1, 3fr);
  grid-gap: $martech-spacer-3;
  grid-column: span 12;

  @include breakpoint(768) {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
